$(document).ready(function() {
  
  window.generateUniqIdentifier = function(){
    return 'a-' + Math.floor(Math.random() * Date.now());
  }

  // To show alert in a customized way
  // displayPlacement: ['normal', 'hover']
  window.alertCustom = function(message, { alertType = 'error', displayPlacement = 'normal', icon = 'bell', oneTimeSessionId = '', autoClose = false } = {}) {
    function cssColorClass(alertType) {
      let cssClass = '';
      switch(alertType) {
        case 'error':
          cssClass = 'alert-danger';
          break;
        case 'alert':
          cssClass = 'alert-warning';
          break;
        case 'notice':
          cssClass = 'alert-primary';
          break;
        case 'success':
          cssClass = 'alert-success';
          break;
        case 'info':
          cssClass = 'alert-info';
          break;
        case 'secondary':
          cssClass = 'alert-secondary';
          break;
      }
      return cssClass;  
    }

    function cssColorType(alertType) {
      let cssClass = '';
      switch(alertType) {
        case 'error':
          cssClass = 'danger';
          break;
        case 'alert':
          cssClass = 'warning';
          break;
        case 'notice':
          cssClass = 'primary';
          break;
        case 'success':
          cssClass = 'success';
          break;
        case 'info':
          cssClass = 'info';
          break;
        case 'secondary':
          cssClass = 'secondary';
          break;
      }
      return cssClass;  
    }

    function iconAlert(icon) {
      let icon_str = '';

      switch(icon) {
        case 'bell':
          icon_str = '<i class="far fa-fw fa-bell"></i>';
          break;
        case 'horn':
          icon_str = '<i class="fas fa-fw fa-bullhorn"></i>';
          break;
      }
      return icon_str;  
    }

    function centerTextIfRequired(alertType) {
      let cssClass = '';

      // if (alertType == 'info'){
      //   cssClass = 'text-center'
      // }
      return cssClass;  
    }

    let flashMessageContainer;
    if (displayPlacement == 'hover'){
      flashMessageContainer = document.getElementById("js-flash-messages-hover");
    } else {
      flashMessageContainer = document.getElementById("js-flash-messages");
    }
    // Adding ql-editor to display quill specific styling. Adding raw styling to remove its sideeffects
    
    let flashCloseJsId = generateUniqIdentifier();
    let loaderHtml     = '';
    if (autoClose){
      loaderHtml = `<div class="spinner-border spinner-border-sm text-${cssColorType(alertType)} me-2" role="status"></div>`;
    }
    flashMessageContainer.innerHTML += `
      <div class="mb-3">
        <div class="ql-editor alert ${cssColorClass(alertType)} alert-outline-coloured alert-dismissible" role="alert" style="white-space: normal; padding: 0;">
          <button type="button" class="btn-close js-close-flash" data-bs-dismiss="alert" aria-label="Close" data-one-time-session-id="${oneTimeSessionId}" id="${flashCloseJsId}"></button>
          <div class="alert-icon">
            ${iconAlert(icon)}
          </div>
          <div class="normal-font alert-message ${centerTextIfRequired(alertType)}">
            ${loaderHtml}
            ${message}
          </div>
        </div>
      </div>
    `;

    if (autoClose){
      setTimeout(function() {
        $(`#${flashCloseJsId}`).click();
      }, 5000)
    }
  }

  window.refreshIconsTooltips = function() {
    feather.replace();
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  // If this a 1 time announcement, we save that user have seen it in session.
  $(document).on('click', '.js-close-flash', function(e){
    let oneTimeSessionId = e.currentTarget.dataset['oneTimeSessionId'];
    if(!!oneTimeSessionId){
      localStorage[oneTimeSessionId] = true;
    }
  })

  const phoneInputField = $('.js-phone-number')[0];
  if(phoneInputField) {
    const phoneInput = window.intlTelInput(phoneInputField, {
      separateDialCode: true,
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });
  }

  $(document).on('change countrychange', '.js-phone-number', function(e) {
    const relatedHiddenFieldId = this.dataset['relatedPhoneField'];
    const enteredNumberWithCode = window.intlTelInputGlobals.getInstance(this).getNumber();

    $(`#${relatedHiddenFieldId}`).val(enteredNumberWithCode);
  });

  // Move to a mailgun feature specific js
  $(document).on('click', '.js-btn-domains-filter', function(e){
    var modalElement = document.getElementById("js-domain-filter-modal");
    bootstrap.Modal.getOrCreateInstance(modalElement).show()
  })
});
